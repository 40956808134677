import React from 'react';
import Layout from '@components/common/Layout';
import Seo from '@components/common/Seo';
import IconCardContainer from '@components/iconCard/IconCardContainer';
import IconCard from '@components/iconCard/IconCard';

const IndexPage = () => (
  <Layout language="sv" segmentId={process.env.GATSBY_SEGMENT_SCREENING_2_PRODUCTION_SE as string}>
    <Seo
      title="Screening"
      description="Screening"
      lang="sv"
    />

    <IconCardContainer>
      <IconCard
        title="Sverige"
        url="/se/sv/"
      />
      <IconCard
        title="United States"
        url="/us/en/"
      />
      <IconCard
        title="France"
        url="/fr/fr/"
      />
    </IconCardContainer>
  </Layout>
);

export default IndexPage;
