import React, { ReactNode } from 'react';

type TIconCardContainer = {
  title?: string,
  children?: ReactNode,
};

const IconCardContainer = ({ title, children }: TIconCardContainer) => (
  <section data-theme="white">
    <div className="container container--sm margin-top--sm">
      {!!title && (
        <div className="text--component margin-bottom--sm text--center text--bold">
          <p>{title}</p>
        </div>
      )}

      <div className="icon-cards margin-bottom--xxl">
        {children}
      </div>
    </div>
  </section>
);

export default IconCardContainer;
