import React, { useEffect, useState } from 'react';
import { isSSR } from '@/src/common/helpers/ssr';

import './iconCard.scss';

type TIconCard = {
  title: string,
  url: string,
  imageSrc?: string,
  imageAlt?: string,
};

const IconCard = ({
  title, url, imageSrc = '', imageAlt = '',
}: TIconCard) => {
  const [queryString, setQueryString] = useState('');

  useEffect(() => {
    setQueryString(isSSR() ? '' : window.location.search);
  }, []);

  return (
    <a href={`${url}${queryString}`} className="icon-cards__card">
      <h5 className="icon-cards__card-title text--center color--electric-blue">{title}</h5>

      {!!imageSrc && (
        <div className="icon-cards__card-image-wrapper">
          <img src={imageSrc} alt={imageAlt || title} />
        </div>
      )}
    </a>
  );
};

export default IconCard;
